import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Resource, Paragraph } from 'components/Landing/Resource/Resource'

const Summary2020: React.FC<RouteComponentProps> = () => {
  return (
    <Resource title='2020 Summary'>
      <Paragraph>
        Happy new year, let&apos;s hope it will be better than the previous one!
      </Paragraph>
      <Paragraph>
        I started working on Dose in February 2020. This small side-project and
        a personal tool soon became an app that thousands of people use
        regularly. I received many e-mails and reviews stating that the app has
        helped users take various substances responsibly. I did not expect that,
        and I&apos;m amazed at how it turned out.
      </Paragraph>
      <Paragraph>
        I am extremely grateful to everyone who participated in this project so
        far - testers, designers, people who shared their feedback and
        suggestions, and people that helped promote it. It would not be possible
        without you!
      </Paragraph>
      <Paragraph>
        I&apos;d like to address an issue with the iOS version of Dose.
        It&apos;s a few versions behind the Android one, and the app is not
        searchable on the App Store. The old version has two major bugs, making
        the export option and the custom date selector unusable. Apple keeps
        rejecting all of the new updates for no reason, but I hope it will
        change soon, and iOS users will get all new fixes and features.
      </Paragraph>
      <Paragraph>Thanks for sticking around!</Paragraph>
      <Paragraph>&mdash; Michał, Developer of Dose</Paragraph>
    </Resource>
  )
}

export default Summary2020
